.promoting {
  padding: 11.75rem 0 6.0625rem 0;
}

section {
  margin-bottom: 5rem !important;
}

.promoting__container {
  display: flex;
}

.optimization-container{
  width: 50%;
}

p:not(.title):not(.title-gradient) {
  line-height: 140%;
  font-size: 1.125rem;
}

.title {
  margin-bottom: 40px;
}



.title-gradient {
  background: linear-gradient(45deg, #ffd234 0%, #fa9d11 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.benefits{
   display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}
.benefits>p {
  display: flex;
  width: 50%;
  min-width: 200px;
  padding: 15px 0;
}

.tick>p {
  display: flex;
  align-items: baseline;
  /*line-height: 1 !important;*/
}

.tick>p::before {
  content: "";
  flex-shrink: 0;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  background: url(/public/img/icons/check-yellow.svg) center/cover no-repeat;
  margin-right: 5px;
  transform: translateY(2px);
}



.optimization-photo{
  width: 50%;
}

.opt-photo-sm {
  display: none;
}

.optimization-photo img{
  width: 100%;
}

.request-button {
  font-size: 1.4rem !important;
  padding: 1rem 3rem !important;
}

.benefits-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.advantage {
  padding: .5rem;
  width: 50%;
}
.advantages-item {
  height: 100%;
}

.choice {
  background: linear-gradient(45deg, #ffd234 0, #fa9d11 100%);
  padding: 3rem 0;
}
.choice, .choice__title {
  color: white;
}
.choices {
  display: flex;
  justify-content: space-between;
}
.choice-item{
  width: 33.33333%;
  padding: 0 1rem;
}
.choice-item_title {
  font-size: 2rem !important;
  font-weight: 800;
  margin-bottom: 1rem;
}
.choice-item>p.item-num{
  font-size: 7rem;
  font-weight: 600;
}
.commerce__container{
  display: flex;
}
.commerce-col, .promotion-col {
  width: 50%;
}
.col-right {
  display: flex;
  justify-content: center;
  align-items: end;
  /*aspect-ratio: 1;*/
}
.circle-wrapper {
  width: 500px;
  height: 600px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  border-radius: 0 0 250px 250px;
  position: relative;
  overflow: hidden;
}

.robot-image {
  width: 120%;
  position: absolute;
  z-index: 2;
  bottom: 0;
}

.half-circle {
  border-radius: 50%;
  width: 500px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  aspect-ratio: 1;
  background: #ffd234;
}

.commerce_text {
  margin: 4rem 0 !important;
}
.commerce_text>p {
  font-size: 1.3rem !important;
  margin: 2rem 0 !important;
}

.promotion-action {
  display: flex;
}

.col-form {
  padding-right: 1rem;
}

.form-col {
  position: relative;
}

.simple-form {
  position: sticky !important;
  top: 20px;
  width: 100%;
}

.promotion-col>p {
  margin: 1rem 0 !important;
}

.promotion-col>p>ol>li {
  margin-left: 1rem !important;
}

@media screen and (max-width: 1080px) {
  .choices {
    flex-wrap: wrap;
    justify-content: center;
  }
  .choice-item {
    width: 50%;
  }
  .commerce-col {
    width: 100%;
  }
  .col-right{
    display: none;
  }
}

@media screen and (max-width: 765px) {
  .choice-item {
    width: 100%;
  }
  .advantage {
    width: 100%;
  }
.title-center-sm {
  text-align: center !important;
}
}
  .title-center {
    text-align: center;
  }
@media screen and (max-width: 850px) {
  .cusstom {
    margin-bottom: 0 !important;
  }
  .m-0-sm {
    margin-bottom: 0 !important;
  }
  .promoting {
    padding-top: 8rem !important;
    padding-bottom: 0;
  }
  .promotion-action {
    flex-direction: column-reverse;
  }
  .promotion-col {
    width: 100%;
  }
  .col-form {
    display: none;
  }
  .optimization-photo {
    display: none;
  }
  .opt-photo-sm {
    display: unset;
    width: 100%;
  }
  .optimization-container {
    width: 100%;
  }
  .title {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 440px) {

  .benefits>p {
    width: 100%;
  }
}
.blog-card-custom {
  cursor: pointer;
  transition: .3s transform;
}
@media screen and (min-width: 1200px) {

.blog-card-custom:hover {
  transform: scale(1.05);
}
}

.blog__card-title_optim {
  user-select: none;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  white-space: break-spaces;
}

/* Біжуча строка з клієнтами */

.marquee-container {
  margin-top: 4rem !important;
}

.marquee-item {
  flex-shrink: 0;
  margin: 0 20px;
}

.rfm-child:last-child {
  margin-right: -150px !important;
}




