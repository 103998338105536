body, html {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Roboto';
  font-display: swap;
  src: url(../public/fonts/Roboto-Bold.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: Roboto;
  font-display: swap;
  src: url(../public/fonts/Roboto-Light.woff2) format("woff2");
  font-weight: 300;
  font-style: normal
}

@font-face {
  font-family: Roboto;
  font-display: swap;
  src: url(../public/fonts/Roboto-Medium.woff2) format("woff2");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: Roboto;
  font-display: swap;
  src: url(../public/fonts/Roboto-Black.woff2) format("woff2");
  font-weight: 900;
  font-style: normal
}

@font-face {
  font-family: Roboto;
  font-display: swap;
  src: url(../public/fonts/Roboto-Regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: Roboto;
  font-display: swap;
  src: url(../public/fonts/Roboto-Italic.woff2) format("woff2");
  font-weight: 400;
  font-style: italic
}

* {
  padding: 0;
  margin: 0;
  border: 0
}

*,
*::after,
*::before {
  box-sizing: border-box
}

*::after,
*::before {
  display: inline-block
}

body,
html {
  height: 100%;
  min-width: 320px;
  scroll-behavior: smooth;
  overflow: auto !important;
}

body {
  color: #15223a;
  line-height: 1;
  font-family: Roboto;
  font-size: 1rem;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

button,
input,
textarea {
  font-family: Roboto;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  background-color: transparent
}

input,
textarea {
  width: 100%
}

button,
option,
select {
  cursor: pointer
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none
}

ul li {
  list-style: none
}

img {
  vertical-align: top
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit
}

body {
  background-color: #fff
}

.lock body {
  overflow: hidden;
  touch-action: none;
  -ms-scroll-chaining: none;
  overscroll-behavior: none
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

@supports (overflow:clip) {
  .wrapper {
    overflow: clip
  }
}

.wrapper>main {
  flex: 1 1 auto
}

.wrapper>* {
  min-width: 0
}

[class*=__container] {
  max-width: 87.875rem;
  margin: 0 auto;
  padding: 0 1.25rem
}

.menu__list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: .75rem
}

.menu__link {
  font-weight: 500;
  font-size: 1rem;
  line-height: 120%;
  text-transform: uppercase;
  transition: color .3s
}

.icon-menu {
  display: none
}

body::after {
  content: "";
  -webkit-backdrop-filter: blur(14.5px);
  backdrop-filter: blur(14.5px);
  background: rgba(36, 103, 220, .17);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity .8s ease 0s;
  pointer-events: none;
  z-index: 99999
}

.popup-show body::after {
  opacity: 1
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 30px 10px;
  transition: visibility .8s ease 0s, transform .8s ease 0s, backdrop-filter .8s ease 0s;
  visibility: hidden;
  pointer-events: none;
  font-family: "Cera Round Pro", Sans-serif
}

.popup_show {
  z-index: 150;
  visibility: visible;
  overflow: auto;
  pointer-events: auto;
  backdrop-filter: brightness(0.7) blur(5px);
}

.popup_show .popup__content {
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1)
}

.popup__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  flex: 1 1 auto;
  width: 100%;
  justify-content: center;
}

.popup__content {
  visibility: hidden;
  transform: scale(0);
  transition: transform .3s ease 0s, -webkit-transform .3s ease 0s;
  box-shadow: 0 4px 43px 0 rgba(0, 0, 0, .12);
  background: #fff;
  width: 100%;
  max-width: 37.5rem;
  border-radius: 1.875rem;
  position: relative
}

.lock .popup__content {
  visibility: visible
}

.popup__close {
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem
}

.popup__close svg {
  width: 100%;
  height: 100%
}

.popup__img {
  display: flex;
  align-items: center;
  justify-content: center
}

.popup__img img {
  -o-object-fit: cover;
  object-fit: cover
}

.popup__title {
  font-weight: 900;
  line-height: 130%;
  text-align: center;
  color: #15223a
}

.popup__text {
  font-weight: 700;
  line-height: 140%;
  text-align: center;
  color: #59667d
}

.active_lang_link {
  color: #fa9d11;
  pointer-events: none;
}

.spollers {
  display: grid;
  margin: 0 auto;
  max-width: 63.875rem
}

.spollers__item {
  border-bottom: 1px solid #dce3ee;
  padding: 1.875rem 0
}


.spollers__title {
  width: 100%;
  cursor: default;
  text-align: left;
  padding: 0 1.75rem 0 0;
  position: relative;
  list-style: none;
  font-weight: 900;
  line-height: 140%;
  color: #15223a;
  cursor: pointer;
}

.spollers__title::-webkit-details-marker,
.spollers__title::marker {
  display: none
}

 .spollers__title {
  cursor: pointer
}



.spollers__body {
  padding: 1.25rem 0 0 0;
  font-weight: 400;
  line-height: 150%;
  color: #59667d
}

.subtitle {
  font-weight: 600;
  line-height: 170%;
  letter-spacing: .16em;
  background: linear-gradient(45deg, #fd9904 0, #ffcb14 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase
}

.subtitle:not(:last-child) {
  margin-bottom: .5rem
}

.title {
  font-weight: 900;
  line-height: 130%;
  color: #15223a;
  text-transform: uppercase
}

.button {
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: .625rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: normal;
  color: #15223a;
  background: linear-gradient(45deg, #ffd234 0, #fa9d11 100%);
  border-radius: 1.5rem;
  padding: .75rem 1.8125rem;
  min-height: 3.625rem;
  transition: box-shadow .3s, opacity .3s
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .75rem 1.25rem;
  flex-wrap: wrap;
  margin-top: 1.5rem;
}

.swiper-pagination-bullet {
  width: .625rem;
  height: .625rem;
  border-radius: 50%;
  background: #c4d0e4;
  cursor: pointer
}

.swiper-pagination-bullet-active {
  background: #15223a
}

.swiper-button-next,
.swiper-button-prev,
.serv-swiper-button-next,
.serv-swiper-button-prev{
  pointer-events: all
}

 .swiper-button-next svg path,
.swiper-button-prev svg path,
 .serv-swiper-button-next svg path,
 .serv-swiper-button-prev svg path{
  transition: all .3s
}

.header {
  position: absolute;
  top: 1.875rem;
  left: 0;
  z-index: 15;
  width: 100%
}

.header__body {
  box-shadow: 0 10px 34px 0 rgba(189, 193, 201, .4);
  background: #fff;
  border-radius: 1.875rem;
  padding: 1rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem
}

.header__logo {
  max-width: 10.9375rem
}

.header__logo img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.header__actions {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  position: relative;
}

.languages {
  position: relative
}

.languages__current {
  border: 2px solid #e3e4e6;
  border-radius: 1.5rem;
  padding: 1.09375rem .96875rem;
  display: flex;
  align-items: center;
  gap: .625rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: normal;
  text-transform: uppercase;
  background: #fff;
  cursor: pointer;
  transition: box-shadow .3s
}

.languages__current::after {
  content: "";
  flex-shrink: 0;
  width: .875rem;
  height: .875rem;
  background: url(../public/img/icons/arrow.svg) center/14px no-repeat;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s
}

.is-open .languages__current::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg)
}

.languages__list {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 8.375rem;
  border-radius: 1.875rem;
  background: #fff;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .9375rem;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  opacity: 0;
  visibility: hidden;
  transition: all .3s
}

.is-open .languages__list {
  top: calc(100% + 10px);
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  opacity: 1;
  visibility: visible;
  box-shadow: 0 10px 34px 0 rgba(189, 193, 201, .4)
}

.languages__link {
  display: flex;
  align-items: center;
  gap: .625rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: normal;
  text-transform: uppercase;
  transition: color .3s
}

.footer__body {
  box-shadow: 0 10px 34px 0 rgba(189, 193, 201, .4);
  background: #fff;
  border-radius: 1.875rem;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2.5rem
}

/*.footer__col {*/
/*  max-width: 19.75rem*/
/*}*/

.footer__logo {
  max-width: 10.9375rem
}

.footer__logo img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.footer__text {
  font-weight: 400;
  line-height: 180%;
  color: rgba(18, 17, 39, .56)
}

.footer__socials {
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap
}

.footer__soc svg path {
  transition: all .3s
}

.footer__block {
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap
}

.footer__menu {
  display: flex;
  flex-direction: column;
  gap: 1.25rem
}

.footer__title {
  font-weight: 600;
  line-height: 120%;
  letter-spacing: .05em;
  text-transform: uppercase;
  color: rgba(18, 17, 39, .4)
}

.footer__link {
  font-weight: 400;
  line-height: 120%;
  color: #121127;
  transition: color .3s;
  text-align: left;
}

.footer__info {
  display: flex;
  flex-direction: column;
  gap: 1.25rem
}

.footer__copy {
  text-align: center;
  font-weight: 400;
  line-height: 180%;
  color: rgba(18, 17, 39, .56)
}

.promo {
  background: linear-gradient(225deg, #fff8ea 0, #f4f5f6 50%, #e9f8fb 100%);
  padding: 11.75rem 0 6.0625rem 0;
  margin-bottom: 8.125rem
}

.promo__body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.25rem;
  position: relative;
  z-index: 3
}

.promo__content {
  align-self: center;
  max-width: 41.75rem;
  min-width: 28.125rem;
  width: 100%
}

.promo__title {
  font-weight: 900;
  line-height: 124%;
  color: #15223a;
  margin-bottom: .8125rem;
  text-transform: uppercase;
}

.promo__text {
  line-height: 150%;
  color: #59667d;
  margin-bottom: 2.375rem
}

.promo__buttons {
  display: flex;
  align-items: center;
  gap: 1rem 1.25rem
}

.promo__buttons .button {
  max-width: 18.625rem;
  width: 100%;
  border-radius: 1.75rem;
  min-height: 4.375rem;
  font-size: 1.125rem
}

.promo__contact {
  background: linear-gradient(45deg, #fff8e4 0, #ffedc9 100%);
  border: 2px solid #ffd234
}

.promo__presentation {
  max-width: 37.6875rem;
  width: 100%;
  margin-right: 2rem;
  padding: 2.6875rem 11.25rem 3.75rem 6.875rem;
  position: relative
}

.promo__presentation::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../public/img/promo-decor.svg) 0 0/contain no-repeat
}

.promo__img {
  max-width: 19.5rem;
  width: 100%;
  aspect-ratio: 312/600;
  position: relative;
  z-index: 3
}

.promo__phone-decor {
  display: block;
  max-width: 19.5rem;
  width: 10px;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-filter: drop-shadow(-20px -16px 84px rgba(21, 34, 58, .1));
  filter: drop-shadow(-20px -16px 84px rgba(21, 34, 58, .1))
}

.promo__phone-content {
  position: absolute;
  bottom: 3.5%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: 85%;
  height: 89%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0 0 1.875rem 1.875rem
}

.promo__decor {
  position: absolute;
  bottom: -6.0625rem;
  right: -19.3125rem;
  z-index: 2;
  max-width: 43.625rem
}

.promo__decor img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.platforms {
  margin-bottom: 8.125rem
}

.platforms__head {
  text-align: center
}

.platforms__items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap
}

.platforms__item {
  flex: 1 1 10.625rem;
  min-height: 12.6875rem;
  border: 1px solid #dce3ee;
  border-radius: 1.875rem;
  padding: 1.25rem;
  display: grid;
  place-content: center
}

.platforms__item img {
  width: 100%;
  max-height: 120px;
  -o-object-fit: contain;
  object-fit: contain
}

.opportunities__body {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 3.4375rem 1.875rem
}

.card-opportunities {
  box-shadow: 0 10px 34px 0 rgba(189, 193, 201, .4);
  background: #fff;
  border-radius: 1.875rem;
  background: url(../public/img/opportunities/card-bg.svg) bottom right/215px no-repeat;
  display: flex;
  flex-direction: column
}

.card-opportunities__title {
  font-weight: 900;
  line-height: 140%;
  margin-bottom: .5625rem
}

.card-opportunities__text {
  flex: 1 1 auto;
  font-weight: 400;
  line-height: 150%;
  color: #59667d;
  margin-bottom: .625rem
}

.card-opportunities__img {
  margin: 0 0 -1.0625rem auto
}

.card-opportunities__img img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.card-opportunities:nth-child(3) .card-opportunities__img {
  margin: 0 0 -1.25rem auto
}

.card-opportunities:nth-child(4) .card-opportunities__img {
  margin: 0 0 -1.25rem auto
}

.card-opportunities:nth-child(5) .card-opportunities__img {
  margin: 0 0 -1.875rem auto
}

.card-opportunities:nth-child(6) .card-opportunities__img {
  margin: 0 -.625rem -2.5rem auto
}

.options {
  margin-bottom: 8.125rem
}

.options__container {
  max-width: 96.875rem
}

.options__body {
  border-radius: 1.875rem;
  background: linear-gradient(225deg, #fff8ea 0, #f4f5f6 50%, #e9f8fb 100%);
  padding: 3.75rem 4.8125rem 4.5625rem 4.8125rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.25rem
}

.options__content {
  max-width: 41.75rem;
  width: 100%
}

.options__list {
  display: flex;
  flex-direction: column;
  gap: 1.25rem
}

.options__item {
  border-radius: 1.875rem;
  box-shadow: 0 10px 34px 0 rgba(189, 193, 201, .4);
  background: #fff;
  padding: 1.5rem 1.375rem;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  align-items: center;
  gap: 1.25rem
}

.options__item::before {
  content: "";
  flex-shrink: 0;
  width: 1.5625rem;
  height: 1.5625rem;
  border-radius: 50%;
  background: url(../public/img/icons/check-yellow.svg) center/cover no-repeat
}

.options__img {
  max-width: 39.0625rem;
  width: 100%
}

.options__img img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.steps {
  margin-bottom: 6.125rem
}

.steps__head {
  text-align: center
}

.steps__head:not(:last-child) {
  margin-bottom: 1.875rem
}

.steps__body {
  display: flex;
  justify-content: space-between;
  gap: 1.25rem
}

.steps__item {
  max-width: 18.4375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.875rem;
  position: relative
}

.steps__item::after {
  content: "";
  position: absolute;
  top: 10%;
  right: -50%;
  max-width: 14.6875rem;
  width: 100%;
  height: 100%;
  background: url(../public/img/steps/steps-line-2.svg) 0 0/contain no-repeat
}

.steps__item:first-child::before {
  content: "";
  position: absolute;
  top: 10%;
  left: -55%;
  max-width: 15.5rem;
  max-height: 13.1875rem;
  width: 100%;
  height: 100%;
  background: url(../public/img/steps/steps-line-1.svg) 0 0/contain no-repeat
}

.steps__item:nth-child(even) {
  flex-direction: column-reverse;
  padding-top: 5.1875rem
}

.steps__item:nth-child(even)::after {
  top: auto;
  bottom: -10%;
  background: url(../public/img/steps/steps-line-3.svg) 0 0/contain no-repeat
}

.steps__item:nth-child(4)::after {
  top: auto;
  bottom: 10%;
  right: -55%;
  max-width: 15.5rem;
  max-height: 13.1875rem;
  background: url(../public/img/steps/steps-line-5.svg) 0 0/contain no-repeat
}

.steps__item-number {
  font-weight: 300;
  line-height: 150%;
  text-align: center;
  color: #d6ddea;
  background-color: #fff;
  padding: 0 .9375rem;
  position: relative;
  z-index: 3
}

.steps__item-img {
  align-self: stretch;
  position: relative
}

.steps__item-img::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  max-width: 14rem;
  max-height: 14rem;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 10px 34px 0 rgba(189, 193, 201, .4);
  z-index: 1
}

.steps__item-img img {
  display: block;
  margin: 0 auto;
  max-width: 12.875rem;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  position: relative;
  z-index: 2
}

.steps__item-text {
  line-height: 150%;
  text-align: center;
  color: #59667d
}

.contacts__form-container {
  width: 100%;
}

.advantages__body {
  position: relative
}

.advantages__navigation {
  position: absolute;
  top: 40%;
  display: flex;
  justify-content: space-between;
  width: calc(100% + 120px);
  left: -3.75rem;
  pointer-events: none
}

.advantages-item {
  border: 1px solid #dce3ee;
  border-radius: 1.875rem;
  height: auto;
}

.advantages-item__head {
  display: flex;
  align-items: center;
  gap: .875rem
}

.advantages-item__head:not(:last-child) {
  margin-bottom: .875rem
}

.advantages-item__num {
  border-radius: 50%;
  background: linear-gradient(45deg, #fd9904 0, #ffcb14 100%);
  min-width: 3.125rem;
  min-height: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 1000;
  font-size: 1.25rem;
  line-height: 100%
}

.advantages-item__title {
  font-weight: 900;
  line-height: 140%
}

.advantages-item__text {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 150%;
  color: #59667d
}

.advantages-item__text span {
  color: #15223a;
  font-weight: 600
}

.tariffs__body {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  row-gap: 1.875rem
}

.tariffs__body-ua {
  grid-template-columns: repeat(4, minmax(290px, 1fr));
}

@media screen and (max-width: 1400px) {
  .tariffs__body-ua {
    grid-template-columns: repeat(2, minmax(290px, 1fr));
  }
}

@media screen and (max-width: 700px) {
  .tariffs__body-ua {
    grid-template-columns: repeat(1, minmax(290px, 1fr));
  }
}

.item-tariffs {
  padding: .6875rem .6875rem 2.0625rem;
  box-shadow: 0 10px 34px 0 rgba(189, 193, 201, .4);
  background: #fff;
  border-radius: 1.875rem;
  display: flex;
  flex-direction: column
}

.item-tariffs--basic .item-tariffs__head {
  background: url(../public/img/tariffs/basic.svg) center/cover no-repeat
}

.item-tariffs--basic .item-tariffs__title {
  background: linear-gradient(-135deg, #2eb000 0, #12e300 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent
}

.item-tariffs--popular .item-tariffs__head {
  background: url(../public/img/tariffs/popular.svg) center/cover no-repeat;
  padding-bottom: 0;
}

.item-tariffs--popular .item-tariffs__title {
  background: linear-gradient(-135deg, #fa9d11 0, #ffd234 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent
}

.item-tariffs--def .item-tariffs__head {
  background: url(../public/img/tariffs/def.svg) center/cover no-repeat;
  padding-bottom: 0;
}

.item-tariffs--def .item-tariffs__title {
  color: blue !important;
  background: none !important;
  -webkit-text-fill-color: unset !important;
}

.item-tariffs--professional .item-tariffs__head {
  background: url(../public/img/tariffs/professional.svg) center/cover no-repeat;
  padding-bottom: 0;
}

.item-tariffs--professional .item-tariffs__title {
  background: linear-gradient(-135deg, #fa11b9 0, #ff7e9d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent
}

.item-tariffs__head {
  border-radius: 1.5rem 1.5rem 0 0;
  padding: 2.125rem 1.25rem;
  margin-bottom: 1.4375rem;
  height: 213px;
}

.choose_container {
  background-color: #ffffff;
  border-radius: 15px 15px 0 0;
  box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.1);
  padding: 15px 10px 10px 10px;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
}

.button_wrapper {
  display: flex;
  justify-content: space-between;
}

.basic_choose_button,
.pro_choose_button {
  user-select: none;
  outline: none;
  background-color: #ffffff;
  border: 1px solid #d3d6db;
  border-radius: 10px;
  width: 32%;
  padding: 10px;
  font-size: 1rem;
  color: #59667d;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.basic_choose_button.selected,
.pro_choose_button.selected {
  background-color: #ffd234;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid #ffd234;
}

.basic_choose_button:hover:not(.selected),
.pro_choose_button:hover:not(.selected) {
  background-color: #f5f5f5;
}

.item-tariffs__title {
  font-weight: 900;
  line-height: 110%;
  letter-spacing: .08em
}

.item-tariffs__title:not(:last-child) {
  margin-bottom: 1.5625rem
}

.item-tariffs__block {
  display: flex;
  flex-flow: column;
  gap: .75rem;
  align-items: flex-start;
  justify-content: end;
  flex-wrap: wrap
}

.item-tariffs__price {
  font-weight: 700;
  line-height: 140%;
  color: #96a1b5
}

.item-tariffs__price span {
  font-weight: 900;
  color: #15223a
}

.item-tariffs__text {
  line-height: 140%;
  color: #96a1b5;
  margin-bottom: .125rem
}

.item-tariffs__body {
  padding: 0 1.25rem;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column
}

.item-tariffs__subtitle {
  font-weight: 600;
  line-height: 120%;
  letter-spacing: .06em
}

.item-tariffs__subtitle:not(:last-child) {
  margin-bottom: .9375rem
}

.item-tariffs__list {
  display: flex;
  flex-direction: column
}

.item-tariffs__list:not(:last-child) {
  margin-bottom: 1.5rem
}

.item-tariffs__li {
  display: flex;
  align-items: center;
  gap: .625rem;
  font-weight: 400;
  line-height: 140%;
  color: #59667d
}

.item-tariffs__li::before {
  content: "";
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: url(../public/img/icons/check-yellow.svg) center/cover no-repeat;
  align-self: flex-start
}

.item-tariffs__li--temp {
  color: #a9afbc
}

.item-tariffs__li--temp::before {
  background: url(../public/img/icons/temp.svg) center/cover no-repeat
}

.item-tariffs__li--disabled {
  color: #a9afbc
}

.item-tariffs__li--disabled::before {
  background: url(../public/img/icons/check-grey.svg) center/cover no-repeat
}

.item-tariffs__note {
  font-style: italic;
  font-weight: 400;
  line-height: 140%;
  color: #59667d;
  border: 1px dashed #c4d0e4;
  border-radius: 24px;
  padding: .875rem 1.875rem;
  margin-top: auto;
  margin-bottom: 1.25rem
}

.item-tariffs__btn {
  /*margin-top: auto;*/
  cursor: pointer;
  max-width: 100%
}

.faq__body {
  background: linear-gradient(225deg, #fff8ea 0, #f4f5f6 50%, #e9f8fb 100%);
  border-radius: 1.875rem;
  padding: 0 1.875rem
}

.services__head {
  text-align: center
}

.services__body {
  position: relative
}

.services__slider {
  padding: 1.5625rem 1.5625rem 0 1.5625rem;
  margin: 0 -1.5625rem;
  border-radius: 1.875rem
}

.services__slide {
  display: flex;
  flex-direction: column
}

.services__navigation {
  position: absolute;
  top: 40%;
  display: flex;
  justify-content: space-between;
  width: calc(100% + 120px);
  left: -3.75rem;
  pointer-events: none
}

.item-services__img {
  overflow: hidden;
  border-radius: 1.875rem;
  -webkit-transform: rotate(-6deg);
  transform: rotate(-6deg)
}

.item-services__img img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.item-services__title {
  box-shadow: 0 10px 18px 0 rgba(189, 193, 201, .4);
  background: #fff;
  margin-top: -6.25rem;
  padding: 8.3125rem 1.875rem 1.375rem;
  border-radius: 1.875rem;
  flex: 1 1 auto;
  font-weight: 900;
  line-height: 140%;
  color: #15223a
}

.contacts__body {
  display: flex;
  align-items: center
}

.contacts__content {
  max-width: 50.1875rem;
  width: 100%;
  border-radius: 1.875rem;
  background: linear-gradient(225deg, #e9f8fb 0, #f4f5f6 50%, #fff8ea 100%);
  padding: 3.75rem 6.875rem 0 4.375rem
}

.contacts__head {
  max-width: 33.4375rem;
  margin-bottom: 2.375rem
}

.contacts__img {
  position: relative;
  padding-left: 35%
}

.contacts__img img {
  max-width: 19.6875rem;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.contacts__img .contacts__decor {
  position: absolute;
  left: -5.625rem;
  top: 3.125rem;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.contacts__form {
  max-width: 40.5rem;
  width: 100%;
  margin-left: -5.3125rem;
  padding: 6.75rem 6rem
}

.form-block {
  border-radius: 1.875rem;
  box-shadow: 0 10px 34px 0 rgba(189, 193, 201, .4);
  background: #fff;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 2rem
}

.form-block__text {
  line-height: 150%;
  color: #59667d
}

.form-block__label {
  font-weight: 700;
  font-size: .875rem;
  line-height: 180%;
  color: rgba(18, 17, 39, .56);
  margin-bottom: .25rem;
  margin-left: 1rem;
}

.form-block__field {
  padding-bottom: 13px;
}

.form-block__field:has(.error-message) {
  padding-bottom: 0;
}

.form-block__input {
  border: 1px solid rgba(18, 17, 39, .12);
  border-radius: 1.5rem;
  padding: .875rem 1rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 100%;
  color: rgba(18, 17, 39, .56);
  min-height: 3.625rem;
  transition: border .3s
}

.form-block__input::-webkit-input-placeholder {
  font-weight: 400;
  color: rgba(18, 17, 39, .4)
}

.form-block__input:-ms-input-placeholder {
  font-weight: 400;
  color: rgba(18, 17, 39, .4)
}

.form-block__input::-ms-input-placeholder {
  font-weight: 400;
  color: rgba(18, 17, 39, .4)
}

.form-block__input::placeholder {
  font-weight: 400;
  color: rgba(18, 17, 39, .4)
}

.form-block__textarea {
  border: 1px solid rgba(18, 17, 39, .12);
  border-radius: 1.5rem;
  padding: .875rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 100%;
  color: rgba(18, 17, 39, .56);
  resize: none;
  min-height: 9.5625rem;
  transition: border .3s
}

.form-block__textarea::-webkit-input-placeholder {
  font-weight: 400;
  color: rgba(18, 17, 39, .4)
}

.form-block__textarea:-ms-input-placeholder {
  font-weight: 400;
  color: rgba(18, 17, 39, .4)
}

.form-block__textarea::-ms-input-placeholder {
  font-weight: 400;
  color: rgba(18, 17, 39, .4)
}

.form-block__textarea::placeholder {
  font-weight: 400;
  color: rgba(18, 17, 39, .4)
}

.form-block__btn {
  max-width: 100%
}

.blog__head {
  text-align: center
}

.blog__slider {
  padding: 0 1.25rem;
  margin: 0 -1.25rem
}

.blog-card {
  background: linear-gradient(70deg, #e9f8fb 0, #f4f5f6 50%, #c7ccd5 100%);
  border-radius: .75rem .75rem 1.875rem 1.875rem;
  overflow: hidden
}

.blog-card__img {
  max-height: 12.5625rem;
  overflow: hidden
}

.blog-card__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.blog-card__body {
  padding: 2rem 1.5rem
}

.blog-card__title {
  font-weight: 700;
  line-height: 124%;
  transition: color .3s;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden
}

.blog-card__title:not(:last-child) {
  margin-bottom: .875rem
}

.blog-card__text {
  line-height: 150%;
  color: #59667d;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden
}

.blog-card__text:not(:last-child) {
  margin-bottom: 5rem
}

.blog-card__author {
  display: flex;
  align-items: flex-start;
  gap: .75rem
}

.blog-card__author-content {
  align-self: center
}

.blog-card__author-name {
  font-weight: 600;
  font-size: .875rem;
  line-height: 120%;
  margin-bottom: .1875rem
}

.blog-card__author-date {
  font-size: .75rem;
  line-height: 120%;
  color: #59667d
}

.blog-card__author-img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  overflow: hidden
}

.blog-card__author-img img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.swiper {
  overflow: hidden
}

.swiper-initialized {
  touch-action: pan-y
}

.swiper-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  display: flex;
  position: relative
}

.swiper-vertical .swiper-wrapper {
  flex-direction: column
}

.swiper-autoheight .swiper-wrapper {
  align-items: flex-start
}

.swiper-initialized .swiper-slide {
  flex-shrink: 0;
  display: flex;
  flex-flow: column;
}

.swiper-android .swiper-slide,
.swiper-android .swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.swiper-button-lock {
  display: none !important
}

@media (min-width:47.99875em) {
  .blog__wrapper {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    gap: 1.875rem
  }
}

@media (min-width:61.99875em) {
    .footer__body {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
        transition: all 1s
    }

    ._watcher-view .footer__body {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    .promo__content {
        -webkit-transform: translate(-150px, 150px);
        transform: translate(-150px, 150px);
        opacity: 0;
        transition: opacity .5s ease-in-out, -webkit-transform .5s ease-in-out;
        transition: transform .5s ease-in-out, opacity .5s ease-in-out;
        transition: transform .5s ease-in-out, opacity .5s ease-in-out, -webkit-transform .5s ease-in-out
    }

    ._watcher-view .promo__content {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
        transition: opacity .6s ease-in-out, -webkit-transform .6s ease-in-out;
        transition: transform .6s ease-in-out, opacity .6s ease-in-out;
        transition: transform .6s ease-in-out, opacity .6s ease-in-out, -webkit-transform .6s ease-in-out
    }

    .promo__img {
        -webkit-transform: translate(150px, 150px);
        transform: translate(150px, 150px);
        opacity: 0;
        transition: opacity .5s ease-in-out, -webkit-transform .5s ease-in-out;
        transition: transform .5s ease-in-out, opacity .5s ease-in-out;
        transition: transform .5s ease-in-out, opacity .5s ease-in-out, -webkit-transform .5s ease-in-out
    }

    ._watcher-view .promo__img {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
        transition: opacity .6s ease-in-out, -webkit-transform .6s ease-in-out;
        transition: transform .6s ease-in-out, opacity .6s ease-in-out;
        transition: transform .6s ease-in-out, opacity .6s ease-in-out, -webkit-transform .6s ease-in-out
    }

    .platforms__item {
        -webkit-transform: translate(0, 150px);
        transform: translate(0, 150px);
        opacity: 0
    }

    .platforms__item:nth-child(1) {
        transition: all .3s ease-in-out
    }

    .platforms__item:nth-child(2) {
        transition: all .6s ease-in-out
    }

    .platforms__item:nth-child(3) {
        transition: all .9s ease-in-out
    }

    .platforms__item:nth-child(4) {
        transition: all 1.2s ease-in-out
    }

    .platforms__item:nth-child(5) {
        transition: all 1.5s ease-in-out
    }

    .platforms__item:nth-child(6) {
        transition: all 1.8s ease-in-out
    }

    ._watcher-view .platforms__item {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1
    }

    .card-opportunities {
        -webkit-transform: translate(0, 150px);
        transform: translate(0, 150px);
        opacity: 0
    }

    .card-opportunities:nth-child(1) {
        transition: all .3s ease-in-out
    }

    .card-opportunities:nth-child(2) {
        transition: all .6s ease-in-out
    }

    .card-opportunities:nth-child(3) {
        transition: all .9s ease-in-out
    }

    .card-opportunities:nth-child(4) {
        transition: all 1.2s ease-in-out
    }

    .card-opportunities:nth-child(5) {
        transition: all 1.5s ease-in-out
    }

    .card-opportunities:nth-child(6) {
        transition: all 1.8s ease-in-out
    }

    ._watcher-view .card-opportunities {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1
    }

    .options__content {
        padding-top: .625rem;
        -webkit-transform: translate(-150px, 150px);
        transform: translate(-150px, 150px);
        opacity: 0;
        transition: opacity .5s ease-in-out, -webkit-transform .5s ease-in-out;
        transition: transform .5s ease-in-out, opacity .5s ease-in-out;
        transition: transform .5s ease-in-out, opacity .5s ease-in-out, -webkit-transform .5s ease-in-out
    }

    ._watcher-view .options__content {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1
    }

    .options__img {
        -webkit-transform: translate(150px, 150px);
        transform: translate(150px, 150px);
        opacity: 0;
        transition: opacity .5s ease-in-out, -webkit-transform .5s ease-in-out;
        transition: transform .5s ease-in-out, opacity .5s ease-in-out;
        transition: transform .5s ease-in-out, opacity .5s ease-in-out, -webkit-transform .5s ease-in-out
    }

    ._watcher-view .options__img {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1
    }

    .steps__item {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }

    .steps__item:nth-child(1) {
        transition: all .5s ease-in-out
    }

    .steps__item:nth-child(2) {
        transition: all 1s ease-in-out
    }

    .steps__item:nth-child(3) {
        transition: all 1.5s ease-in-out
    }

    .steps__item:nth-child(4) {
        transition: all 2s ease-in-out
    }

    ._watcher-view .steps__item {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    .advantages-item {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }

    .advantages-item:nth-child(1) {
        transition: all .7s ease-in-out
    }

    .advantages-item:nth-child(2) {
        transition: all 1.4s ease-in-out
    }

    .advantages-item:nth-child(3) {
        transition: all 2.1s ease-in-out
    }

    .advantages-item:nth-child(4) {
        transition: all 2.8s ease-in-out
    }

    ._watcher-view .advantages-item {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    .item-tariffs {
        -webkit-transform: translate(0, 150px);
        transform: translate(0, 150px);
        opacity: 0
    }

    .item-tariffs:nth-child(1) {
        transition: all .5s ease-in-out
    }

    .item-tariffs:nth-child(2) {
        transition: all 1s ease-in-out
    }

    .item-tariffs:nth-child(3) {
        transition: all 1.5s ease-in-out
    }

    .item-tariffs:nth-child(4) {
      transition: all 2s ease-in-out
    }

    ._watcher-view .item-tariffs {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1
    }

    .faq__body {
        -webkit-transform: translate(0, 150px);
        transform: translate(0, 150px);
        opacity: 0;
        transition: all .6s ease-in-out
    }

    ._watcher-view .faq__body {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1
    }

    .item-services__img {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        transition: all .7s
    }

    ._watcher-view .item-services__img {
        -webkit-transform: rotate(-6deg);
        transform: rotate(-6deg)
    }

    .form-block {
        -webkit-transform: translate(150px, 150px);
        transform: translate(150px, 150px);
        opacity: 0;
        transition: opacity .5s ease-in-out, -webkit-transform .5s ease-in-out;
        transition: transform .5s ease-in-out, opacity .5s ease-in-out;
        transition: transform .5s ease-in-out, opacity .5s ease-in-out, -webkit-transform .5s ease-in-out
    }

    ._watcher-view .form-block {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1
    }

    .blog-card {
        -webkit-transform: translate(0, 150px);
        transform: translate(0, 150px);
        opacity: 0
    }

    .blog-card:nth-child(1) {
        transition: all .3s ease-in-out
    }

    .blog-card:nth-child(2) {
        transition: all .6s ease-in-out
    }

    .blog-card:nth-child(3) {
        transition: all .9s ease-in-out
    }

    .blog-card:nth-child(4) {
        transition: all 1.2s ease-in-out
    }

    ._watcher-view .blog-card {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1
    }
}

@media (min-width:68.74875em) {
    .header__body {
        -webkit-transform: scale(.3);
        transform: scale(.3);
        opacity: 0;
        transition: -webkit-transform 1s ease-in-out;
        transition: transform 1s ease-in-out;
        transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out
    }

    ._watcher-view .header__body {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@media (min-width:87.875em) {
  .menu__list {
    -webkit-column-gap: 3rem;
    -moz-column-gap: 3rem;
    column-gap: 3rem
  }

  .popup__content {
    padding: 3.75rem
  }

  .popup__img {
    margin-bottom: 1.875rem
  }

  .popup__img img {
    width: 6.25rem
  }

  .popup__img img {
    height: 6.25rem
  }

  .popup__title {
    font-size: 2rem
  }

  .popup__title {
    margin-bottom: 1.25rem
  }

  .popup__text {
    font-size: 1.375rem
  }

  .spollers__title {
    font-size: 1.5rem
  }

  .spollers__body {
    font-size: 1.125rem
  }

  .subtitle {
    font-size: 1.125rem
  }

  .title {
    font-size: 3rem
  }

  .footer__body {
    padding-top: 5.6875rem
  }

  .footer__body {
    padding-bottom: 5.6875rem
  }

  .footer__body {
    padding-right: 5.6875rem
  }

  .footer__body {
    padding-left: 5.6875rem
  }

  .footer__body {
    margin-bottom: 3.5rem
  }

  .footer__logo {
    margin-bottom: 1.5rem
  }

  .footer__text {
    font-size: 1rem
  }

  .footer__text {
    margin-bottom: 1.5rem
  }

  .footer__block {
    gap: 9.875rem
  }

  .footer__title {
    font-size: 1rem
  }

  .footer__link {
    font-size: 1rem
  }

  .footer__copy {
    margin-bottom: 3.5rem
  }

  .footer__copy {
    font-size: 1rem
  }

  .promo__title {
    font-size: 3.5rem
  }

  .promo__text {
    font-size: 1.5rem
  }

  .platforms {
    margin-bottom: 8.125rem
  }

  .platforms__head:not(:last-child) {
    margin-bottom: 3.9375rem
  }

  .platforms__items {
    gap: 1.875rem
  }

  .opportunities {
    margin-bottom: 8.125rem
  }

  .opportunities__head:not(:last-child) {
    margin-bottom: 3.625rem
  }

  .card-opportunities {
    padding-top: 3.5625rem
  }

  .card-opportunities {
    padding-left: 2.5rem
  }

  .card-opportunities {
    padding-right: 2.5rem
  }

  .card-opportunities__title {
    font-size: 1.5rem
  }

  .card-opportunities__text {
    font-size: 1.0625rem
  }

  .card-opportunities__img {
    max-width: 11.375rem
  }

  .options {
    margin-bottom: 8.125rem
  }

  .options__head:not(:last-child) {
    margin-bottom: 2.6875rem
  }

  .options__item {
    font-size: 1.25rem
  }

  .steps__item-number {
    font-size: 4.875rem
  }

  .steps__item-text {
    font-size: 1.125rem
  }

  .advantages {
    margin-bottom: 8.125rem
  }

  .advantages__head:not(:last-child) {
    margin-bottom: 3.625rem
  }

  .advantages__wrapper {
    margin-bottom: 3.5625rem
  }

  .advantages-item {
    padding: 2.1875rem
  }

  .advantages-item__title {
    font-size: 1.5rem
  }

  .advantages-item__text {
    font-size: 1.125rem
  }

  .tariffs {
    margin-bottom: 8.75rem
  }

  .tariffs__head:not(:last-child) {
    margin-bottom: 3.625rem
  }

  .tariffs__body {
    -webkit-column-gap: 1.875rem;
    -moz-column-gap: 1.875rem;
    column-gap: 1.875rem
  }

  .item-tariffs__title {
    font-size: 1.5rem
  }

  .item-tariffs__price {
    font-size: 1.25rem
  }

  .item-tariffs__price span {
    font-size: 3.375rem
  }

  .item-tariffs__text {
    font-size: .875rem
  }

  .item-tariffs__subtitle {
    font-size: 1rem
  }

  .item-tariffs__list {
    gap: 1rem
  }

  .item-tariffs__li {
    font-size: 1rem
  }

  .item-tariffs__note {
    font-size: 1rem
  }

  .faq {
    margin-bottom: 8.125rem
  }

  .faq__body {
    padding-bottom: 6.875rem
  }

  .faq__body {
    padding-top: 6.875rem
  }

  .services {
    margin-bottom: 8.75rem
  }

  .services__head:not(:last-child) {
    margin-bottom: 3.4375rem
  }

  .services__wrapper {
    margin-bottom: 4.1875rem
  }

  .item-services__title {
    font-size: 1.25rem
  }

  .contacts {
    margin-bottom: 8.125rem
  }

  .contacts__img .contacts__decor {
    max-width: 12.1875rem
  }

  .form-block__text {
    font-size: 1.125rem
  }

  .blog {
    margin-bottom: 8.75rem
  }

  .blog__head:not(:last-child) {
    margin-bottom: 3.625rem
  }

  .blog-card__title {
    font-size: 1.25rem
  }

  .blog-card__text {
    font-size: 1rem
  }
}

@media (max-width:20em) {
  .popup__content {
    padding: 1.875rem
  }

  .popup__img {
    margin-bottom: 1rem
  }

  .popup__img img {
    width: 4.375rem
  }

  .popup__img img {
    height: 4.375rem
  }

  .popup__title {
    font-size: 1.5rem
  }

  .popup__title {
    margin-bottom: .75rem
  }

  .popup__text {
    font-size: 1rem
  }

  .spollers__title {
    font-size: 1.125rem
  }

  .spollers__body {
    font-size: 1rem
  }

  .subtitle {
    font-size: .875rem
  }

  .title {
    font-size: 1.75rem
  }

  .footer__body {
    padding-top: 3rem
  }

  .footer__body {
    padding-bottom: 3rem
  }

  .footer__body {
    padding-right: 1.75rem
  }

  .footer__body {
    padding-left: 1.75rem
  }

  .footer__body {
    margin-bottom: 2.5rem
  }

  .footer__logo {
    margin-bottom: 1rem
  }

  .footer__text {
    font-size: .875rem
  }

  .footer__text {
    margin-bottom: 1rem
  }

  .footer__title {
    font-size: .875rem
  }

  .footer__link {
    font-size: .875rem
  }

  .footer__copy {
    margin-bottom: 2.5rem
  }

  .footer__copy {
    font-size: .875rem
  }

  .promo__title {
    font-size: 1.875rem
  }

  .promo__text {
    font-size: 1rem
  }

  .platforms {
    margin-bottom: 3.9375rem
  }

  .platforms__head:not(:last-child) {
    margin-bottom: 2rem
  }

  .platforms__items {
    gap: 1.0625rem
  }

  .opportunities {
    margin-bottom: 2.875rem
  }

  .opportunities__head:not(:last-child) {
    margin-bottom: 2rem
  }

  .card-opportunities {
    padding-top: 2.125rem
  }

  .card-opportunities {
    padding-left: 1.875rem
  }

  .card-opportunities {
    padding-right: 1.25rem
  }

  .card-opportunities__title {
    font-size: 1.125rem
  }

  .card-opportunities__text {
    font-size: 1rem
  }

  .card-opportunities__img {
    max-width: 5rem
  }

  .options {
    margin-bottom: 3.9375rem
  }

  .options__head:not(:last-child) {
    margin-bottom: 2rem
  }

  .options__item {
    font-size: 1rem
  }

  .steps__item-number {
    font-size: 3.625rem
  }

  .steps__item-text {
    font-size: 1rem
  }

  .advantages {
    margin-bottom: 3.9375rem
  }

  .advantages__head:not(:last-child) {
    margin-bottom: 2rem
  }

  .advantages__wrapper {
    margin-bottom: 1.875rem
  }

  .advantages-item {
    padding: 1.4375rem
  }

  .advantages-item__title {
    font-size: 1.125rem
  }

  .advantages-item__text {
    font-size: 1rem
  }

  .tariffs {
    margin-bottom: 2.5rem
  }

  .tariffs__head:not(:last-child) {
    margin-bottom: 2rem
  }

  .tariffs__body {
    -webkit-column-gap: .9375rem;
    -moz-column-gap: .9375rem;
    column-gap: .9375rem
  }

  .item-tariffs__title {
    font-size: 1.25rem
  }

  .item-tariffs__price {
    font-size: .875rem
  }

  .item-tariffs__text {
    font-size: .75rem
  }

  .item-tariffs__subtitle {
    font-size: .875rem
  }

  .item-tariffs__list {
    gap: .5rem
  }

  .item-tariffs__li {
    font-size: .875rem
  }

  .item-tariffs__note {
    font-size: .875rem
  }

  .faq {
    margin-bottom: 3.9375rem
  }

  .faq__body {
    padding-bottom: 2.0625rem
  }

  .faq__body {
    padding-top: 2.0625rem
  }

  .services {
    margin-bottom: 4.375rem
  }

  .services__head:not(:last-child) {
    margin-bottom: 1.6875rem
  }

  .services__wrapper {
    margin-bottom: 1.875rem
  }

  .item-services__title {
    font-size: 1.125rem
  }

  .contacts {
    margin-bottom: 3.75rem
  }

  .form-block__text {
    font-size: 1rem
  }

  .blog {
    margin-bottom: 4.375rem
  }

  .blog__head:not(:last-child) {
    margin-bottom: 2rem
  }

  .blog-card__title {
    font-size: 1.125rem
  }

  .blog-card__text {
    font-size: .875rem
  }
}

@media (max-width:93.74875em) {
  .advantages__body {
    padding: 0 1.875rem
  }

  .advantages__navigation {
    left: 0;
    width: 100%
  }

  .services__body {
    padding: 0 1.875rem
  }

  .services__navigation {
    left: 0;
    width: 100%
  }
}

@media (max-width:87.875em) {
  .options__body {
    padding: 3.9375rem 1.875rem 4.375rem 1.25rem;
    margin: 0 -1.25rem;
    border-radius: 0
  }
}

@media (max-width:78.12375em) {
  .steps__body {
    flex-direction: column;
    align-items: center
  }

  .steps__item::after {
    background: url(../public/img/steps/steps-line-2-mob.svg) 0 0/contain no-repeat;
    max-width: 100%;
    height: 110%;
    right: -65%;
    top: 13%
  }

  .steps__item:first-child::before {
    background: url(../public/img/steps/steps-line-1-mob.svg) 0 0/contain no-repeat;
    max-width: 100%;
    max-height: 100%;
    top: -28%
  }

  .steps__item:nth-child(even) {
    flex-direction: column;
    padding-top: 0
  }

  .steps__item:nth-child(even)::after {
    background: url(../public/img/steps/steps-line-3-mob.svg) 0 0/contain no-repeat;
    max-width: 100%;
    max-height: 100%;
    top: 15%;
    left: -30%;
    bottom: auto;
    height: 110%
  }

  .steps__item:nth-child(4)::after {
    background: url(../public/img/steps/steps-line-5-mob.svg) 0 0/contain no-repeat;
    max-width: 100%;
    max-height: 100%;
    bottom: auto;
    right: auto;
    top: 14%;
    left: -55%
  }

  .steps__item {
    gap: .625rem
  }

  .steps__item-img::before {
    max-width: 8.125rem;
    max-height: 8.125rem;
    margin-bottom: .625rem
  }

  .steps__item-img img {
    max-width: 8.75rem;
    margin: 0 auto -.9375rem
  }
}

@media (max-width:74.99875em) {
  .contacts__content {
    padding: 1.875rem 6.875rem 0 1.875rem
  }

  .contacts__img .contacts__decor {
    left: -3.125rem
  }
}

@media (max-width:68.74875em) {
  .menu__body {
    position: fixed;
    z-index: 15;
    width: 100%;
    height: 100%;
    left: -100%;
    top: 0;
    overflow: auto;
    padding: 8.75rem .9375rem 3.125rem .9375rem;
    background-color: #fff;
    transition: left .3s
  }

  .menu__body::before {
    content: "";
    position: fixed;
    width: 100%;
    height: 6.875rem;
    left: -100%;
    top: 0;
    background-color: #fff;
    transition: left .3s;
    z-index: 2
  }

  .menu-open .menu__body {
    left: 0
  }

  .menu-open .menu__body::before {
    left: 0
  }

  .menu__list {
    flex-direction: column;
    row-gap: 3.125rem
  }

  .menu__list:not(:last-child) {
    margin-bottom: 4.375rem
  }

  .icon-menu {
    display: block;
    position: relative;
    width: 1.875rem;
    height: 1.125rem;
    z-index: 17
  }

  .icon-menu span,
  .icon-menu::after,
  .icon-menu::before {
    content: "";
    transition: all .3s ease 0s;
    right: 0;
    position: absolute;
    width: 100%;
    height: .125rem;
    border-radius: .0625rem;
    background-color: #15223a
  }

  .icon-menu::before {
    top: 0
  }

  .icon-menu::after {
    width: 60%;
    bottom: 0
  }

  .icon-menu span {
    top: calc(50% - .0625rem)
  }

  .menu-open .icon-menu span {
    width: 0
  }

  .menu-open .icon-menu::before {
    top: calc(50% - .0625rem);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
  }

  .menu-open .icon-menu::after {
    width: 100%;
    bottom: calc(50% - .0625rem);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
  }

  .header__logo {
    position: relative;
    z-index: 17
  }

  .header__actions {
    margin-left: auto
  }
}

@media (max-width:68.74875em) and (any-hover:none) {
  .icon-menu {
    cursor: default
  }
}

@media (max-width:61.99875em) {
  .menu__list {
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem
  }

  .button {
    min-height: 3.125rem;
    border-radius: 1.25rem
  }

  .languages__current {
    padding: .84375rem
  }

  .footer__block {
    gap: 2.5rem
  }

  .promo {
    padding: 11.25rem 0 0 0;
    margin-bottom: 11.875rem
  }

  .promo__body {
    flex-direction: column;
    gap: .625rem
  }

  .promo__content {
    min-width: 0
  }

  .promo__title {
    text-align: center
  }

  .promo__text {
    text-align: center
  }

  .promo__buttons {
    justify-content: center
  }

  .promo__buttons .button {
    border-radius: 1.25rem;
    min-height: 3.125rem;
    font-size: 1rem
  }

  .promo__presentation {
    max-width: 100%;
    padding: 11.25rem 2.8125rem 0 2.8125rem
  }

  .promo__presentation::before {
    background: url(../public/img/promo-decor-mob.svg) top center/contain no-repeat
  }

  .promo__img {
    margin: 0 auto -8.75rem auto
  }

  .promo__decor {
    max-width: 16.875rem;
    width: 100%;
    bottom: 40%;
    right: 50%;
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0)
  }

  .options__body {
    flex-direction: column
  }

  .options__content {
    max-width: 100%
  }

  .options__img {
    max-width: 100%
  }

  .advantages__body {
    padding: 0
  }

  .advantages__slider {
    margin: 0 -1.25rem;
    padding: 0 1.25rem
  }

  .advantages__navigation {
    display: none
  }

  .advantages-item__num {
    min-width: 2.625rem;
    min-height: 2.625rem
  }

  .item-tariffs__price span {
    font-size: 2.75rem
  }

  .services__body {
    padding: 0
  }

  .services__slider {
    margin: 0 -1.25rem;
    padding: 1.25rem 1.25rem 0 1.25rem
  }

  .services__navigation {
    display: none
  }

  .contacts__form {
    padding: 1.875rem 1.5625rem
  }
}

@media (max-width:47.99875em) {
  .spollers__body {
    padding: .625rem 0 0 0
  }

  .header__actions {
    flex-direction: column;
    gap: 1.875rem
  }

  .header__btn {
    max-width: 18.125rem;
    width: 100%
  }

  .languages {
    max-width: 18.125rem;
    width: 100%
  }

  .languages__current {
    width: 100%;
    justify-content: center
  }

  .languages__list {
    width: 100%;
    justify-content: center
  }

  .opportunities__body {
    gap: 2.1875rem .9375rem
  }

  .card-opportunities {
    background: url(../public/img/opportunities/card-bg.svg) bottom right/177px no-repeat
  }

  .options__item {
    padding: 1.25rem;
    gap: .9375rem
  }

  .item-tariffs__note {
    padding: .875rem
  }

  .contacts__body {
    flex-direction: column;
    align-items: stretch
  }

  .contacts__content {
    padding: 2.4375rem 1.25rem 15.625rem 1.25rem;
    width: auto;
    margin: 0 -1.25rem;
    border-radius: 0
  }

  .contacts__img .contacts__decor {
    max-width: 7.125rem
  }

  .contacts__img .contacts__decor {
    max-width: 10.625rem;
    position: static
  }

  .contacts__img {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: start;
    padding-left: 1.25rem;
    gap: .9375rem
  }

  .contacts__form {
    max-width: 100%;
    margin: -23.125rem 0 0 0
  }

  .form-block {
    gap: 1.25rem
  }

  .form-block__input {
    min-height: 3.125rem
  }

  .form-block__textarea {
    min-height: 6.9375rem
  }

  .blog__wrapper {
    margin-bottom: 1.875rem
  }

  .blog-card__text:not(:last-child) {
    margin-bottom: 2.0625rem
  }
}

@media (max-width:37.49875em) {
  .menu__body::before {
    height: 5.3125rem
  }

  .header {
    top: 1.25rem
  }

  .header__body {
    padding: .84375rem 1.25rem;
    border-radius: 1.25rem
  }

  .header__logo {
    max-width: 7.1875rem
  }

  .promo {
    padding: 9.125rem 0 0 0
  }

  .promo__text {
    margin-bottom: 1.25rem
  }

  .promo__buttons {
    flex-direction: column
  }
}

@media (max-width:29.99875em) {
  .popup {
    padding: 64px 13px 20px
  }

  .footer__body {
    justify-content: center;
    text-align: center
  }

  .footer__logo {
    max-width: 7.1875rem
  }

  .footer__socials {
    justify-content: center
  }

  .footer__block {

    justify-content: center
  }

  .footer__link {
    text-align: center;
  }

  .platforms__item {
    min-height: 9.875rem;
    flex: 1 1 9.375rem
  }

  .opportunities__body {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr))
  }

  .card-opportunities {
    background: url(../public/img/opportunities/card-bg.svg) bottom right/137px no-repeat
  }

  .card-opportunities:nth-child(6) .card-opportunities__img {
    margin: 0 -.625rem -1.25rem auto
  }

  .contacts__content {
    padding: 2.4375rem 1.25rem 16.25rem 1.25rem
  }

  .contacts__head {
    margin-bottom: 1.25rem
  }

  .contacts__img .contacts__decor {
    max-width: 7.125rem
  }

  .contacts__form {
    margin: -21.25rem 0 0 0
  }
}

@media (max-width:29.99875em) and (max-width:19.99875em) {
  .promo__decor {
    bottom: 12.5rem
  }
}

@media (max-width:24.99875em) {
  .promo__phone-content {
    border-radius: 0 0 1.5625rem 1.5625rem
  }

  .contacts__content {
    padding: 2.4375rem 1.25rem 18.125rem 1.25rem
  }

  .contacts__form {
    margin: -23.125rem 0 0 0
  }
}

@media (min-width:61.99875em) and (max-width:87.875em) {
  @supports ((-moz-column-gap: clamp(1.25rem, -7.7348920342rem + 14.492053524vw, 5rem)) or (column-gap:clamp(1.25rem, -7.7348920342rem + 14.492053524vw, 5rem))) {
    .menu__list {
      -webkit-column-gap: 1.25rem;
      -moz-column-gap: 1.25rem;
      column-gap: 1.25rem
    }
  }

  @supports not ((-moz-column-gap:clamp(1.25rem, -7.7348920342rem + 14.492053524vw, 5rem)) or (column-gap:clamp(1.25rem, -7.7348920342rem + 14.492053524vw, 5rem))) {
    .menu__list {
      -webkit-column-gap: calc(1.25rem + 3.75 * (100vw - 61.99875rem)/ 25.87625);
      -moz-column-gap: calc(1.25rem + 3.75 * (100vw - 61.99875rem)/ 25.87625);
      column-gap: calc(1.25rem + 3.75 * (100vw - 61.99875rem)/ 25.87625)
    }
  }

  @supports (gap:clamp(2.5rem, -15.1702876673rem + 28.5010385972vw, 9.875rem)) {
    .footer__block {
      gap: clamp(2.5rem, -15.1702876673rem + 28.5010385972vw, 9.875rem)
    }
  }

  @supports not (gap:clamp(2.5rem, -15.1702876673rem + 28.5010385972vw, 9.875rem)) {
    .footer__block {
      gap: calc(2.5rem + 7.375 * (100vw - 61.99875rem)/ 25.87625)
    }
  }

  @supports (font-size:clamp(2.75rem, 1.2525179943rem + 2.415342254vw, 3.375rem)) {
    .item-tariffs__price span {
      font-size: clamp(2.75rem, 1.2525179943rem + 2.415342254vw, 3.375rem)
    }
  }

  @supports not (font-size:clamp(2.75rem, 1.2525179943rem + 2.415342254vw, 3.375rem)) {
    .item-tariffs__price span {
      font-size: calc(2.75rem + .625 * (100vw - 61.99875rem)/ 25.87625)
    }
  }
}

@media (min-width:20em) and (max-width:87.875em) {
  @supports (padding: clamp(1.875rem, 1.3225138122rem + 2.7624309392vw, 3.75rem)) {
    .popup__content {
      padding: clamp(1.875rem, 1.3225138122rem + 2.7624309392vw, 3.75rem)
    }
  }

  @supports not (padding:clamp(1.875rem, 1.3225138122rem + 2.7624309392vw, 3.75rem)) {
    .popup__content {
      padding: calc(1.875rem + 1.875 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(1rem, 0.7421731123rem + 1.2891344383vw, 1.875rem)) {
    .popup__img {
      margin-bottom: clamp(1rem, .7421731123rem + 1.2891344383vw, 1.875rem)
    }
  }

  @supports not (margin-bottom:clamp(1rem, 0.7421731123rem + 1.2891344383vw, 1.875rem)) {
    .popup__img {
      margin-bottom: calc(1rem + .875 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (width:clamp(4.375rem, 3.8225138122rem + 2.7624309392vw, 6.25rem)) {
    .popup__img img {
      width: clamp(4.375rem, 3.8225138122rem + 2.7624309392vw, 6.25rem)
    }
  }

  @supports not (width:clamp(4.375rem, 3.8225138122rem + 2.7624309392vw, 6.25rem)) {
    .popup__img img {
      width: calc(4.375rem + 1.875 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (height:clamp(4.375rem, 3.8225138122rem + 2.7624309392vw, 6.25rem)) {
    .popup__img img {
      height: clamp(4.375rem, 3.8225138122rem + 2.7624309392vw, 6.25rem)
    }
  }

  @supports not (height:clamp(4.375rem, 3.8225138122rem + 2.7624309392vw, 6.25rem)) {
    .popup__img img {
      height: calc(4.375rem + 1.875 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1.5rem, 1.3526703499rem + 0.7366482505vw, 2rem)) {
    .popup__title {
      font-size: clamp(1.5rem, 1.3526703499rem + .7366482505vw, 2rem)
    }
  }

  @supports not (font-size:clamp(1.5rem, 1.3526703499rem + 0.7366482505vw, 2rem)) {
    .popup__title {
      font-size: calc(1.5rem + .5 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(0.75rem, 0.6026703499rem + 0.7366482505vw, 1.25rem)) {
    .popup__title {
      margin-bottom: clamp(.75rem, .6026703499rem + .7366482505vw, 1.25rem)
    }
  }

  @supports not (margin-bottom:clamp(0.75rem, 0.6026703499rem + 0.7366482505vw, 1.25rem)) {
    .popup__title {
      margin-bottom: calc(.75rem + .5 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1rem, 0.8895027624rem + 0.5524861878vw, 1.375rem)) {
    .popup__text {
      font-size: clamp(1rem, .8895027624rem + .5524861878vw, 1.375rem)
    }
  }

  @supports not (font-size:clamp(1rem, 0.8895027624rem + 0.5524861878vw, 1.375rem)) {
    .popup__text {
      font-size: calc(1rem + .375 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1.125rem, 1.0145027624rem + 0.5524861878vw, 1.5rem)) {
    .spollers__title {
      font-size: clamp(1.125rem, 1.0145027624rem + .5524861878vw, 1.5rem)
    }
  }

  @supports not (font-size:clamp(1.125rem, 1.0145027624rem + 0.5524861878vw, 1.5rem)) {
    .spollers__title {
      font-size: calc(1.125rem + .375 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1rem, 0.9631675875rem + 0.1841620626vw, 1.125rem)) {
    .spollers__body {
      font-size: clamp(1rem, .9631675875rem + .1841620626vw, 1.125rem)
    }
  }

  @supports not (font-size:clamp(1rem, 0.9631675875rem + 0.1841620626vw, 1.125rem)) {
    .spollers__body {
      font-size: calc(1rem + .125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(0.875rem, 0.801335175rem + 0.3683241252vw, 1.125rem)) {
    .subtitle {
      font-size: clamp(.875rem, .801335175rem + .3683241252vw, 1.125rem)
    }
  }

  @supports not (font-size:clamp(0.875rem, 0.801335175rem + 0.3683241252vw, 1.125rem)) {
    .subtitle {
      font-size: calc(.875rem + .25 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1.75rem, 1.3816758748rem + 1.8416206262vw, 3rem)) {
    .title {
      font-size: clamp(1.75rem, 1.3816758748rem + 1.8416206262vw, 3rem)
    }
  }

  @supports not (font-size:clamp(1.75rem, 1.3816758748rem + 1.8416206262vw, 3rem)) {
    .title {
      font-size: calc(1.75rem + 1.25 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (padding-top:clamp(3rem, 2.2081031308rem + 3.9594843462vw, 5.6875rem)) {
    .footer__body {
      padding-top: clamp(3rem, 2.2081031308rem + 3.9594843462vw, 5.6875rem)
    }
  }

  @supports not (padding-top:clamp(3rem, 2.2081031308rem + 3.9594843462vw, 5.6875rem)) {
    .footer__body {
      padding-top: calc(3rem + 2.6875 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (padding-bottom:clamp(3rem, 2.2081031308rem + 3.9594843462vw, 5.6875rem)) {
    .footer__body {
      padding-bottom: clamp(3rem, 2.2081031308rem + 3.9594843462vw, 5.6875rem)
    }
  }

  @supports not (padding-bottom:clamp(3rem, 2.2081031308rem + 3.9594843462vw, 5.6875rem)) {
    .footer__body {
      padding-bottom: calc(3rem + 2.6875 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (padding-right:clamp(1.75rem, 0.5897790055rem + 5.8011049724vw, 5.6875rem)) {
    .footer__body {
      padding-right: clamp(1.75rem, .5897790055rem + 5.8011049724vw, 5.6875rem)
    }
  }

  @supports not (padding-right:clamp(1.75rem, 0.5897790055rem + 5.8011049724vw, 5.6875rem)) {
    .footer__body {
      padding-right: calc(1.75rem + 3.9375 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (padding-left:clamp(1.75rem, 0.5897790055rem + 5.8011049724vw, 5.6875rem)) {
    .footer__body {
      padding-left: clamp(1.75rem, .5897790055rem + 5.8011049724vw, 5.6875rem)
    }
  }

  @supports not (padding-left:clamp(1.75rem, 0.5897790055rem + 5.8011049724vw, 5.6875rem)) {
    .footer__body {
      padding-left: calc(1.75rem + 3.9375 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(2.5rem, 2.2053406998rem + 1.4732965009vw, 3.5rem)) {
    .footer__body {
      margin-bottom: clamp(2.5rem, 2.2053406998rem + 1.4732965009vw, 3.5rem)
    }
  }

  @supports not (margin-bottom:clamp(2.5rem, 2.2053406998rem + 1.4732965009vw, 3.5rem)) {
    .footer__body {
      margin-bottom: calc(2.5rem + 1 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(1rem, 0.8526703499rem + 0.7366482505vw, 1.5rem)) {
    .footer__logo {
      margin-bottom: clamp(1rem, .8526703499rem + .7366482505vw, 1.5rem)
    }
  }

  @supports not (margin-bottom:clamp(1rem, 0.8526703499rem + 0.7366482505vw, 1.5rem)) {
    .footer__logo {
      margin-bottom: calc(1rem + .5 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .footer__text {
      font-size: clamp(.875rem, .8381675875rem + .1841620626vw, 1rem)
    }
  }

  @supports not (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .footer__text {
      font-size: calc(.875rem + .125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(1rem, 0.8526703499rem + 0.7366482505vw, 1.5rem)) {
    .footer__text {
      margin-bottom: clamp(1rem, .8526703499rem + .7366482505vw, 1.5rem)
    }
  }

  @supports not (margin-bottom:clamp(1rem, 0.8526703499rem + 0.7366482505vw, 1.5rem)) {
    .footer__text {
      margin-bottom: calc(1rem + .5 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .footer__title {
      font-size: clamp(.875rem, .8381675875rem + .1841620626vw, 1rem)
    }
  }

  @supports not (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .footer__title {
      font-size: calc(.875rem + .125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .footer__link {
      font-size: clamp(.875rem, .8381675875rem + .1841620626vw, 1rem)
    }
  }

  @supports not (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .footer__link {
      font-size: calc(.875rem + .125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(2.5rem, 2.2053406998rem + 1.4732965009vw, 3.5rem)) {
    .footer__copy {
      margin-bottom: clamp(2.5rem, 2.2053406998rem + 1.4732965009vw, 3.5rem)
    }
  }

  @supports not (margin-bottom:clamp(2.5rem, 2.2053406998rem + 1.4732965009vw, 3.5rem)) {
    .footer__copy {
      margin-bottom: calc(2.5rem + 1 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .footer__copy {
      font-size: clamp(.875rem, .8381675875rem + .1841620626vw, 1rem)
    }
  }

  @supports not (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .footer__copy {
      font-size: calc(.875rem + .125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1.875rem, 1.3961786372rem + 2.394106814vw, 3.5rem)) {
    .promo__title {
      font-size: clamp(1.875rem, 1.3961786372rem + 2.394106814vw, 3.5rem)
    }
  }

  @supports not (font-size:clamp(1.875rem, 1.3961786372rem + 2.394106814vw, 3.5rem)) {
    .promo__title {
      font-size: calc(1.875rem + 1.625 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1rem, 0.8526703499rem + 0.7366482505vw, 1.5rem)) {
    .promo__text {
      font-size: clamp(1rem, .8526703499rem + .7366482505vw, 1.5rem)
    }
  }

  @supports not (font-size:clamp(1rem, 0.8526703499rem + 0.7366482505vw, 1.5rem)) {
    .promo__text {
      font-size: calc(1rem + .5 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(3.9375rem, 2.7036141805rem + 6.1694290976vw, 8.125rem)) {
    .platforms {
      margin-bottom: clamp(3.9375rem, 2.7036141805rem + 6.1694290976vw, 8.125rem)
    }
  }

  @supports not (margin-bottom:clamp(3.9375rem, 2.7036141805rem + 6.1694290976vw, 8.125rem)) {
    .platforms {
      margin-bottom: calc(3.9375rem + 4.1875 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(2rem, 1.4290976059rem + 2.8545119705vw, 3.9375rem)) {
    .platforms__head:not(:last-child) {
      margin-bottom: clamp(2rem, 1.4290976059rem + 2.8545119705vw, 3.9375rem)
    }
  }

  @supports not (margin-bottom:clamp(2rem, 1.4290976059rem + 2.8545119705vw, 3.9375rem)) {
    .platforms__head:not(:last-child) {
      margin-bottom: calc(2rem + 1.9375 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (gap:clamp(1.0625rem, 0.8230893186rem + 1.197053407vw, 1.875rem)) {
    .platforms__items {
      gap: clamp(1.0625rem, .8230893186rem + 1.197053407vw, 1.875rem)
    }
  }

  @supports not (gap:clamp(1.0625rem, 0.8230893186rem + 1.197053407vw, 1.875rem)) {
    .platforms__items {
      gap: calc(1.0625rem + .8125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(2.875rem, 1.328038674rem + 7.7348066298vw, 8.125rem)) {
    .opportunities {
      margin-bottom: clamp(2.875rem, 1.328038674rem + 7.7348066298vw, 8.125rem)
    }
  }

  @supports not (margin-bottom:clamp(2.875rem, 1.328038674rem + 7.7348066298vw, 8.125rem)) {
    .opportunities {
      margin-bottom: calc(2.875rem + 5.25 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(2rem, 1.5211786372rem + 2.394106814vw, 3.625rem)) {
    .opportunities__head:not(:last-child) {
      margin-bottom: clamp(2rem, 1.5211786372rem + 2.394106814vw, 3.625rem)
    }
  }

  @supports not (margin-bottom:clamp(2rem, 1.5211786372rem + 2.394106814vw, 3.625rem)) {
    .opportunities__head:not(:last-child) {
      margin-bottom: calc(2rem + 1.625 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (padding-top:clamp(2.125rem, 1.701427256rem + 2.1178637201vw, 3.5625rem)) {
    .card-opportunities {
      padding-top: clamp(2.125rem, 1.701427256rem + 2.1178637201vw, 3.5625rem)
    }
  }

  @supports not (padding-top:clamp(2.125rem, 1.701427256rem + 2.1178637201vw, 3.5625rem)) {
    .card-opportunities {
      padding-top: calc(2.125rem + 1.4375 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (padding-left:clamp(1.875rem, 1.6908379374rem + 0.9208103131vw, 2.5rem)) {
    .card-opportunities {
      padding-left: clamp(1.875rem, 1.6908379374rem + .9208103131vw, 2.5rem)
    }
  }

  @supports not (padding-left:clamp(1.875rem, 1.6908379374rem + 0.9208103131vw, 2.5rem)) {
    .card-opportunities {
      padding-left: calc(1.875rem + .625 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (padding-right:clamp(1.25rem, 0.8816758748rem + 1.8416206262vw, 2.5rem)) {
    .card-opportunities {
      padding-right: clamp(1.25rem, .8816758748rem + 1.8416206262vw, 2.5rem)
    }
  }

  @supports not (padding-right:clamp(1.25rem, 0.8816758748rem + 1.8416206262vw, 2.5rem)) {
    .card-opportunities {
      padding-right: calc(1.25rem + 1.25 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1.125rem, 1.0145027624rem + 0.5524861878vw, 1.5rem)) {
    .card-opportunities__title {
      font-size: clamp(1.125rem, 1.0145027624rem + .5524861878vw, 1.5rem)
    }
  }

  @supports not (font-size:clamp(1.125rem, 1.0145027624rem + 0.5524861878vw, 1.5rem)) {
    .card-opportunities__title {
      font-size: calc(1.125rem + .375 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1rem, 0.9815837937rem + 0.0920810313vw, 1.0625rem)) {
    .card-opportunities__text {
      font-size: clamp(1rem, .9815837937rem + .0920810313vw, 1.0625rem)
    }
  }

  @supports not (font-size:clamp(1rem, 0.9815837937rem + 0.0920810313vw, 1.0625rem)) {
    .card-opportunities__text {
      font-size: calc(1rem + .0625 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (max-width:clamp(5rem, 3.1215469613rem + 9.3922651934vw, 11.375rem)) {
    .card-opportunities__img {
      max-width: clamp(5rem, 3.1215469613rem + 9.3922651934vw, 11.375rem)
    }
  }

  @supports not (max-width:clamp(5rem, 3.1215469613rem + 9.3922651934vw, 11.375rem)) {
    .card-opportunities__img {
      max-width: calc(5rem + 6.375 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(3.9375rem, 2.7036141805rem + 6.1694290976vw, 8.125rem)) {
    .options {
      margin-bottom: clamp(3.9375rem, 2.7036141805rem + 6.1694290976vw, 8.125rem)
    }
  }

  @supports not (margin-bottom:clamp(3.9375rem, 2.7036141805rem + 6.1694290976vw, 8.125rem)) {
    .options {
      margin-bottom: calc(3.9375rem + 4.1875 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(2rem, 1.7974217311rem + 1.0128913444vw, 2.6875rem)) {
    .options__head:not(:last-child) {
      margin-bottom: clamp(2rem, 1.7974217311rem + 1.0128913444vw, 2.6875rem)
    }
  }

  @supports not (margin-bottom:clamp(2rem, 1.7974217311rem + 1.0128913444vw, 2.6875rem)) {
    .options__head:not(:last-child) {
      margin-bottom: calc(2rem + .6875 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1rem, 0.926335175rem + 0.3683241252vw, 1.25rem)) {
    .options__item {
      font-size: clamp(1rem, .926335175rem + .3683241252vw, 1.25rem)
    }
  }

  @supports not (font-size:clamp(1rem, 0.926335175rem + 0.3683241252vw, 1.25rem)) {
    .options__item {
      font-size: calc(1rem + .25 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(3.625rem, 3.2566758748rem + 1.8416206262vw, 4.875rem)) {
    .steps__item-number {
      font-size: clamp(3.625rem, 3.2566758748rem + 1.8416206262vw, 4.875rem)
    }
  }

  @supports not (font-size:clamp(3.625rem, 3.2566758748rem + 1.8416206262vw, 4.875rem)) {
    .steps__item-number {
      font-size: calc(3.625rem + 1.25 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1rem, 0.9631675875rem + 0.1841620626vw, 1.125rem)) {
    .steps__item-text {
      font-size: clamp(1rem, .9631675875rem + .1841620626vw, 1.125rem)
    }
  }

  @supports not (font-size:clamp(1rem, 0.9631675875rem + 0.1841620626vw, 1.125rem)) {
    .steps__item-text {
      font-size: calc(1rem + .125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(3.9375rem, 2.7036141805rem + 6.1694290976vw, 8.125rem)) {
    .advantages {
      margin-bottom: clamp(3.9375rem, 2.7036141805rem + 6.1694290976vw, 8.125rem)
    }
  }

  @supports not (margin-bottom:clamp(3.9375rem, 2.7036141805rem + 6.1694290976vw, 8.125rem)) {
    .advantages {
      margin-bottom: calc(3.9375rem + 4.1875 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(2rem, 1.5211786372rem + 2.394106814vw, 3.625rem)) {
    .advantages__head:not(:last-child) {
      margin-bottom: clamp(2rem, 1.5211786372rem + 2.394106814vw, 3.625rem)
    }
  }

  @supports not (margin-bottom:clamp(2rem, 1.5211786372rem + 2.394106814vw, 3.625rem)) {
    .advantages__head:not(:last-child) {
      margin-bottom: calc(2rem + 1.625 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(1.875rem, 1.3777624309rem + 2.4861878453vw, 3.5625rem)) {
    .advantages__wrapper {
      margin-bottom: clamp(1.875rem, 1.3777624309rem + 2.4861878453vw, 3.5625rem)
    }
  }

  @supports not (margin-bottom:clamp(1.875rem, 1.3777624309rem + 2.4861878453vw, 3.5625rem)) {
    .advantages__wrapper {
      margin-bottom: calc(1.875rem + 1.6875 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (padding:clamp(1.4375rem, 1.2165055249rem + 1.1049723757vw, 2.1875rem)) {
    .advantages-item {
      padding: clamp(1.4375rem, 1.2165055249rem + 1.1049723757vw, 2.1875rem)
    }
  }

  @supports not (padding:clamp(1.4375rem, 1.2165055249rem + 1.1049723757vw, 2.1875rem)) {
    .advantages-item {
      padding: calc(1.4375rem + .75 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1.125rem, 1.0145027624rem + 0.5524861878vw, 1.5rem)) {
    .advantages-item__title {
      font-size: clamp(1.125rem, 1.0145027624rem + .5524861878vw, 1.5rem)
    }
  }

  @supports not (font-size:clamp(1.125rem, 1.0145027624rem + 0.5524861878vw, 1.5rem)) {
    .advantages-item__title {
      font-size: calc(1.125rem + .375 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1rem, 0.9631675875rem + 0.1841620626vw, 1.125rem)) {
    .advantages-item__text {
      font-size: clamp(1rem, .9631675875rem + .1841620626vw, 1.125rem)
    }
  }

  @supports not (font-size:clamp(1rem, 0.9631675875rem + 0.1841620626vw, 1.125rem)) {
    .advantages-item__text {
      font-size: calc(1rem + .125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(2.5rem, 0.6583793738rem + 9.2081031308vw, 8.75rem)) {
    .tariffs {
      margin-bottom: clamp(2.5rem, .6583793738rem + 9.2081031308vw, 8.75rem)
    }
  }

  @supports not (margin-bottom:clamp(2.5rem, 0.6583793738rem + 9.2081031308vw, 8.75rem)) {
    .tariffs {
      margin-bottom: calc(2.5rem + 6.25 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(2rem, 1.5211786372rem + 2.394106814vw, 3.625rem)) {
    .tariffs__head:not(:last-child) {
      margin-bottom: clamp(2rem, 1.5211786372rem + 2.394106814vw, 3.625rem)
    }
  }

  @supports not (margin-bottom:clamp(2rem, 1.5211786372rem + 2.394106814vw, 3.625rem)) {
    .tariffs__head:not(:last-child) {
      margin-bottom: calc(2rem + 1.625 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports ((-moz-column-gap:clamp(0.9375rem, 0.6612569061rem + 1.3812154696vw, 1.875rem)) or (column-gap:clamp(0.9375rem, 0.6612569061rem + 1.3812154696vw, 1.875rem))) {
    .tariffs__body {
      -webkit-column-gap: clamp(.9375rem, .6612569061rem + 1.3812154696vw, 1.875rem);
      -moz-column-gap: clamp(.9375rem, .6612569061rem + 1.3812154696vw, 1.875rem);
      column-gap: clamp(.9375rem, .6612569061rem + 1.3812154696vw, 1.875rem)
    }
  }

  @supports not ((-moz-column-gap:clamp(0.9375rem, 0.6612569061rem + 1.3812154696vw, 1.875rem)) or (column-gap:clamp(0.9375rem, 0.6612569061rem + 1.3812154696vw, 1.875rem))) {
    .tariffs__body {
      -webkit-column-gap: calc(.9375rem + .9375 * (100vw - 20rem)/ 67.875);
      -moz-column-gap: calc(.9375rem + .9375 * (100vw - 20rem)/ 67.875);
      column-gap: calc(.9375rem + .9375 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1.25rem, 1.176335175rem + 0.3683241252vw, 1.5rem)) {
    .item-tariffs__title {
      font-size: clamp(1.25rem, 1.176335175rem + .3683241252vw, 1.5rem)
    }
  }

  @supports not (font-size:clamp(1.25rem, 1.176335175rem + 0.3683241252vw, 1.5rem)) {
    .item-tariffs__title {
      font-size: calc(1.25rem + .25 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(0.875rem, 0.7645027624rem + 0.5524861878vw, 1.25rem)) {
    .item-tariffs__price {
      font-size: clamp(.875rem, .7645027624rem + .5524861878vw, 1.25rem)
    }
  }

  @supports not (font-size:clamp(0.875rem, 0.7645027624rem + 0.5524861878vw, 1.25rem)) {
    .item-tariffs__price {
      font-size: calc(.875rem + .375 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(0.75rem, 0.7131675875rem + 0.1841620626vw, 0.875rem)) {
    .item-tariffs__text {
      font-size: clamp(.75rem, .7131675875rem + .1841620626vw, .875rem)
    }
  }

  @supports not (font-size:clamp(0.75rem, 0.7131675875rem + 0.1841620626vw, 0.875rem)) {
    .item-tariffs__text {
      font-size: calc(.75rem + .125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .item-tariffs__subtitle {
      font-size: clamp(.875rem, .8381675875rem + .1841620626vw, 1rem)
    }
  }

  @supports not (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .item-tariffs__subtitle {
      font-size: calc(.875rem + .125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (gap:clamp(0.5rem, 0.3526703499rem + 0.7366482505vw, 1rem)) {
    .item-tariffs__list {
      gap: clamp(.5rem, .3526703499rem + .7366482505vw, 1rem)
    }
  }

  @supports not (gap:clamp(0.5rem, 0.3526703499rem + 0.7366482505vw, 1rem)) {
    .item-tariffs__list {
      gap: calc(.5rem + .5 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .item-tariffs__li {
      font-size: clamp(.875rem, .8381675875rem + .1841620626vw, 1rem)
    }
  }

  @supports not (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .item-tariffs__li {
      font-size: calc(.875rem + .125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .item-tariffs__note {
      font-size: clamp(.875rem, .8381675875rem + .1841620626vw, 1rem)
    }
  }

  @supports not (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .item-tariffs__note {
      font-size: calc(.875rem + .125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(3.9375rem, 2.7036141805rem + 6.1694290976vw, 8.125rem)) {
    .faq {
      margin-bottom: clamp(3.9375rem, 2.7036141805rem + 6.1694290976vw, 8.125rem)
    }
  }

  @supports not (margin-bottom:clamp(3.9375rem, 2.7036141805rem + 6.1694290976vw, 8.125rem)) {
    .faq {
      margin-bottom: calc(3.9375rem + 4.1875 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (padding-bottom:clamp(2.0625rem, 0.6444521179rem + 7.0902394107vw, 6.875rem)) {
    .faq__body {
      padding-bottom: clamp(2.0625rem, .6444521179rem + 7.0902394107vw, 6.875rem)
    }
  }

  @supports not (padding-bottom:clamp(2.0625rem, 0.6444521179rem + 7.0902394107vw, 6.875rem)) {
    .faq__body {
      padding-bottom: calc(2.0625rem + 4.8125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (padding-top:clamp(2.0625rem, 0.6444521179rem + 7.0902394107vw, 6.875rem)) {
    .faq__body {
      padding-top: clamp(2.0625rem, .6444521179rem + 7.0902394107vw, 6.875rem)
    }
  }

  @supports not (padding-top:clamp(2.0625rem, 0.6444521179rem + 7.0902394107vw, 6.875rem)) {
    .faq__body {
      padding-top: calc(2.0625rem + 4.8125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(4.375rem, 3.0858655617rem + 6.4456721915vw, 8.75rem)) {
    .services {
      margin-bottom: clamp(4.375rem, 3.0858655617rem + 6.4456721915vw, 8.75rem)
    }
  }

  @supports not (margin-bottom:clamp(4.375rem, 3.0858655617rem + 6.4456721915vw, 8.75rem)) {
    .services {
      margin-bottom: calc(4.375rem + 4.375 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(1.6875rem, 1.1718462247rem + 2.5782688766vw, 3.4375rem)) {
    .services__head:not(:last-child) {
      margin-bottom: clamp(1.6875rem, 1.1718462247rem + 2.5782688766vw, 3.4375rem)
    }
  }

  @supports not (margin-bottom:clamp(1.6875rem, 1.1718462247rem + 2.5782688766vw, 3.4375rem)) {
    .services__head:not(:last-child) {
      margin-bottom: calc(1.6875rem + 1.75 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(1.875rem, 1.1936003683rem + 3.4069981584vw, 4.1875rem)) {
    .services__wrapper {
      margin-bottom: clamp(1.875rem, 1.1936003683rem + 3.4069981584vw, 4.1875rem)
    }
  }

  @supports not (margin-bottom:clamp(1.875rem, 1.1936003683rem + 3.4069981584vw, 4.1875rem)) {
    .services__wrapper {
      margin-bottom: calc(1.875rem + 2.3125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1.125rem, 1.0881675875rem + 0.1841620626vw, 1.25rem)) {
    .item-services__title {
      font-size: clamp(1.125rem, 1.0881675875rem + .1841620626vw, 1.25rem)
    }
  }

  @supports not (font-size:clamp(1.125rem, 1.0881675875rem + 0.1841620626vw, 1.25rem)) {
    .item-services__title {
      font-size: calc(1.125rem + .125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(3.75rem, 2.4608655617rem + 6.4456721915vw, 8.125rem)) {
    .contacts {
      margin-bottom: clamp(3.75rem, 2.4608655617rem + 6.4456721915vw, 8.125rem)
    }
  }

  @supports not (margin-bottom:clamp(3.75rem, 2.4608655617rem + 6.4456721915vw, 8.125rem)) {
    .contacts {
      margin-bottom: calc(3.75rem + 4.375 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1rem, 0.9631675875rem + 0.1841620626vw, 1.125rem)) {
    .form-block__text {
      font-size: clamp(1rem, .9631675875rem + .1841620626vw, 1.125rem)
    }
  }

  @supports not (font-size:clamp(1rem, 0.9631675875rem + 0.1841620626vw, 1.125rem)) {
    .form-block__text {
      font-size: calc(1rem + .125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(4.375rem, 3.0858655617rem + 6.4456721915vw, 8.75rem)) {
    .blog {
      margin-bottom: clamp(4.375rem, 3.0858655617rem + 6.4456721915vw, 8.75rem)
    }
  }

  @supports not (margin-bottom:clamp(4.375rem, 3.0858655617rem + 6.4456721915vw, 8.75rem)) {
    .blog {
      margin-bottom: calc(4.375rem + 4.375 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (margin-bottom:clamp(2rem, 1.5211786372rem + 2.394106814vw, 3.625rem)) {
    .blog__head:not(:last-child) {
      margin-bottom: clamp(2rem, 1.5211786372rem + 2.394106814vw, 3.625rem)
    }
  }

  @supports not (margin-bottom:clamp(2rem, 1.5211786372rem + 2.394106814vw, 3.625rem)) {
    .blog__head:not(:last-child) {
      margin-bottom: calc(2rem + 1.625 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(1.125rem, 1.0881675875rem + 0.1841620626vw, 1.25rem)) {
    .blog-card__title {
      font-size: clamp(1.125rem, 1.0881675875rem + .1841620626vw, 1.25rem)
    }
  }

  @supports not (font-size:clamp(1.125rem, 1.0881675875rem + 0.1841620626vw, 1.25rem)) {
    .blog-card__title {
      font-size: calc(1.125rem + .125 * (100vw - 20rem)/ 67.875)
    }
  }

  @supports (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .blog-card__text {
      font-size: clamp(.875rem, .8381675875rem + .1841620626vw, 1rem)
    }
  }

  @supports not (font-size:clamp(0.875rem, 0.8381675875rem + 0.1841620626vw, 1rem)) {
    .blog-card__text {
      font-size: calc(.875rem + .125 * (100vw - 20rem)/ 67.875)
    }
  }
}

@media (max-width:29.99875em) and (min-width:29.99875em) {
  .promo__decor {
    bottom: 25.625rem
  }
}

@media (max-width:29.99875em) and (min-width:19.99875em) and (max-width:29.99875em) {
  @supports (bottom: clamp(12.5rem, -13.748359375rem + 131.25vw, 25.625rem)) {
    .promo__decor {
      bottom: clamp(12.5rem, -13.748359375rem + 131.25vw, 25.625rem)
    }
  }

  @supports not (bottom:clamp(12.5rem, -13.748359375rem + 131.25vw, 25.625rem)) {
    .promo__decor {
      bottom: calc(12.5rem + 13.125 * (100vw - 19.99875rem)/ 10)
    }
  }
}

@media (min-width:47.99875em) and (max-width:87.875em) {
  @supports (max-width: clamp(7.125rem, 1.0313058368rem + 12.695526786vw, 12.1875rem)) {
    .contacts__img .contacts__decor {
      max-width: clamp(7.125rem, 1.0313058368rem + 12.695526786vw, 12.1875rem)
    }
  }

  @supports not (max-width:clamp(7.125rem, 1.0313058368rem + 12.695526786vw, 12.1875rem)) {
    .contacts__img .contacts__decor {
      max-width: calc(7.125rem + 5.0625 * (100vw - 47.99875rem)/ 39.87625)
    }
  }
}

@media (any-hover:hover) {
  .menu__link:hover {
    color: #fa9d11
  }

  .button:hover {
    opacity: .9;
    box-shadow: 0 10px 34px 0 rgba(189, 193, 201, .4)
  }

  .swiper-button-next:hover svg path,
  .swiper-button-prev:hover svg path,
  .serv-swiper-button-next:hover svg path,
  .serv-swiper-button-prev:hover svg path{
    fill: #15223a
  }

  .languages__current:hover {
    box-shadow: 0 10px 34px 0 rgba(189, 193, 201, .4)
  }

  .languages__link:hover {
    color: #fa9d11
  }

  .footer__soc:hover path {
    fill: #fa9d11;
    fill-opacity: 1
  }

  .footer__link:hover {
    color: #fa9d11
  }

  .form-block__input:hover {
    border: 1px solid rgba(18, 17, 39, .5)
  }

  .form-block__textarea:hover {
    border: 1px solid rgba(18, 17, 39, .5)
  }

  .blog-card__title:hover {
    color: #fa9d11
  }
}

a.download-link {
  color: #fff;
  margin: 0 0 20px 20px;
  font-family: Arial;
}

.progress-circle__container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

.progress-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: conic-gradient(#fa9d11 0%,
  #ffd234 92%,
  white 92%,
  white 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-circle__inner {
  background: white;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  font-size: 24px;
  color: #2C2C2C;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-circle__text {
  margin-top: 20px;
}

.calc-container {
  width: 50%;
}

@media screen and (max-width: 900px) {
  .calc-container {
    width: 100%;
  }

  .calc-plus {
    padding-top: 2em;
  }
}

.calculator {
  display: grid;
  gap: 25px;
  max-width: 700px;
  margin: 0 auto;
  padding: 1.5em;
}

.calculator label {
  display: flex;
  justify-content: space-between;
}

.calculator input {
  border-radius: 15px;
  max-width: 25%;
  border: 1px solid #eaa845;
  padding: 5px 10px;
  font-size: 16px;
  color: #eaa845;
  width: 70px;
}

.calculator button {
  justify-self: center;
}

.calculator_result {
  font-weight: bold;
  font-size: 1.1em;
}

/* Стилі для плаваючої кнопки */
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 30px;
  width: 60px;
  height: 60px;
  background-color: #1C93E3;
  /* Колір кнопки (Telegram синій) */
  color: white;
  border-radius: 50%;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 9999;
}

.floating-button:hover {
  background-color: #0077b5;
  /* Колір кнопки при наведенні */
}

.floating-button img {
  width: 100%;
  height: 100%;
}

.tariffs {
  padding-bottom: 300px;
  margin-bottom: -200px !important;
}

@media screen and (max-width: 700px) {
  .floating-button {
    right: 20px;
  }
}

.accordion-collapse {
  height: 0;
  overflow: hidden;
  transition: height 0.4s ease;
}

.accordion-collapse.show {
  height: auto;
}

.accordion-header::after,
.accordion-header::before {
  content: "";
  position: absolute;
  right: 3px;
  top: 15px;
  background-color: #15223a;
  height: 2px;
  width: 9px;
  transition: -webkit-transform .5s ease 0s;
  transition: transform .5s ease 0s;
  transition: transform .5s ease 0s, -webkit-transform .5s ease 0s
}

.accordion-header::before {
  -webkit-transform: translate(-75%, -50%) rotate(40deg);
  transform: translate(-75%, -50%) rotate(40deg)
}

.accordion-header::after {
  -webkit-transform: translate(0, -50%) rotate(-40deg);
  transform: translate(0, -50%) rotate(-40deg)
}

/*.spollers__title._spoller-active::before {*/
/*  -webkit-transform: translateX(-75%) rotate(-40deg);*/
/*  transform: translateX(-75%) rotate(-40deg)*/
/*}*/

/*.spollers__title._spoller-active::after {*/
/*  -webkit-transform: rotate(40deg);*/
/*  transform: rotate(40deg)*/
/*}*/

.accordion-button {
  font-weight: 900;
}

.react-tel-input .form-control {
  border: 1px solid rgba(18, 17, 39, .12);
  border-radius: 1.5rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 100%;
  color: rgba(18, 17, 39, .56);
  min-height: 3.625rem;
  transition: border .3s;
  width: 100%;
  padding-left: 3.5rem;
}
.flag-dropdown {
  border-radius: 1.5rem 0 0 1.5rem !important;
  padding: 0 .7rem !important;
  padding-right: .6rem !important;
  background: none !important;
  border: none !important;
  border-right: 1px solid rgba(18, 17, 39, .12) !important;
}
.react-tel-input .selected-flag {
  display: flex;
  align-items: center;
  width: unset;
  padding: 0;
}

.flag-dropdown .selected-flag:hover, .flag-dropdown.open .selected-flag {
  background: none !important;
  /*width: 30px !important*/
}

.flag {
  position: initial !important;
  border-radius: 50% !important;
  width: 25px !important;
  height: 25px !important;
  margin: 0 !important;

}

.react-tel-input .es {
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../public/img/spain-flag.svg") !important;
}

.react-tel-input .ua {
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../public/img/ukraine-flag.svg") !important;
}
.react-tel-input .us {
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../public/img/united-states.png") !important;
}

.arrow {
  display: none !important;
}

.country-list {
  border-radius: 15px !important;
  overflow: hidden !important;
}

.country-list .country {
  display: flex;
  align-items: center;

}

.country-list .country:not(:last-child) {
  border-bottom: 1px solid #f4f4f4;
}

.country-name {
  margin-left: 5px;
}

.error-message {
  font-size: small;
  color: #c60000;
  margin-left: 1rem;
}
.header__socials{
  display: flex;
  gap: .5rem;
  position: absolute;
  right: -120px !important;
}

.social-icon{
  width: 40px;
  height: 40px;
}






/*@media screen and (max-width: 806px) {*/
/*  .header__btn {*/
/*    display: none;*/
/*  }*/
/*}*/

