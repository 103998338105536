.blog__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2em 0;
    margin-top: 10rem !important;
    margin-bottom: 2em !important;
}

.blog__card {
    width: 31%;
    min-width: 250px;
    aspect-ratio: 0.66;
    border-radius: 2em;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    transition: .3s box-shadow, .3s transform;
    overflow: hidden;
}

.blog__card:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);
}

.blog__card img {
    height: 70%;
    width: 100%;
    object-fit: cover;
}

.blog__card-info {
    padding: 2em;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 30%;
}

.blog__card-title {
    font-size: 1.5rem;
    line-height: 1.2;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 3;
}

.post__title {
    font-size: 2em !important;
    margin: .5em 0 !important;
}

.date {
    margin: 1em 0;
}

.post {
    margin: 2rem auto;
    max-width: 800px;
}

.post img {
    max-width: 600px;
    width: 100%;
    margin: 1rem auto;
}

.post img.stars {
    max-width: 300px;
    width: 60%;
    margin-bottom: 2rem;
}

.post p {
    line-height: 1.5;
    margin: 0 0 .5em 0;
    font-size: 1.1rem;
    text-align: justify;
}

.post__text {
    margin-top: 2em;
    display: flex;
    flex-flow: column;
}

.text-bullet {
    margin-left: 15px !important;
    position: relative;
}

.text-bullet::before {
    content: "•";
    position: absolute;
    font-weight: 600;
    font-size: 18px;
    left: -15px;
    /* Можна змінити, щоб контролювати позицію крапки відносно тексту */
}

.navigate-back {
    width: 40px;
    height: 40px;
    max-width: 40px !important;
    opacity: 0.6;
    transition: .3s opacity;
    cursor: pointer;
}

.navigate-back:hover {
    opacity: 1;
}



.post p a {
    color: #fa9d11;
}


@media screen and (max-width: 839px) {
    .blog__card {
        width: 49%;
    }
    .blog__container {
        margin-top: 9rem !important;
    }
}
@media screen and (max-width: 599px) {
    .blog__container {
        padding-top: 100px;
    }

    .post p {
        text-align: unset;
    }

    .blog__container {
        margin-top: 6.5rem !important;
    }
}

@media screen and (max-width: 561px) {
    .blog__card {
        width: 100%;
    }
}

@media screen and (max-width: 1233px) {
    .blog__card-info {
        padding: 1em;
    }

    .blog__card-date {
        padding-left: 1em;
    }

}